import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { Select } from '../../../shared/select'
import Button from '../../../shared/button'
import Flag from '../../../shared/flag'
import { TrashIcon } from '@heroicons/react/outline'
import { QUERY_ADMIN_RESOUCE_SEARCH } from '../../../admin/admin-search-select-dialog'
import { getSystemName } from '../../../../helpers/systems.helper'
import useGetCategoryName from '../../../../hooks/useGetCategoryName'
import { useTranslation } from 'react-i18next'
import { useDisconnectBadgeFromSystem } from '../../hooks/useDisconnectBadgeFromSystem'
import Dialog from '../../../shared/dialog'
import { DocumentTextIcon } from '@heroicons/react/solid'
import { useConnectBadgeToSystem } from '../../hooks/useConncetBadgeToSystem'

const BadgeSystemConnection = ({ badge, refetchBadges, renderListItem }) => {
  const { t } = useTranslation('adminPages')
  const [selectedSystem, setSelectedSystem] = useState(undefined)
  const [selectedSystemSiteData, setSelectedSystemSiteData] = useState('')
  const [availableSystemSites, setAvailableSystemSites] = useState([])
  const [error, setError] = useState(null)
  const { disconnectBadgeToSystem } = useDisconnectBadgeFromSystem()
  const { connectBadgeToSystem } = useConnectBadgeToSystem()

  const { getCategoryName } = useGetCategoryName()
  const [adminResourceSearch, { data }] = useLazyQuery(QUERY_ADMIN_RESOUCE_SEARCH)
  const [isListOpen, setIsListOpen] = useState(false)
  const adminSearchDebounce = useDebouncedCallback((search) => {
    adminResourceSearch({
      fetchPolicy: 'network-only',
      variables: { search }
    })
  }, 800)

  const allSystems = data?.appAdminResourceSearch?.systems || []
  const allSystemsOptions = allSystems.map((system) => ({
    type: 'SYSTEM',
    label: getSystemName(system),
    value: system.id,
    subtitle: getCategoryName(system.defaultCategory)
  }))

  useEffect(() => {
    if (selectedSystem) {
      const system = allSystems.find((system) => system.id === selectedSystem)
      const availableSites = system?.systemSiteData?.map((site) => ({
        label: t('shared:localeToCountry.' + site.locale),
        value: site.locale
      }))
      setAvailableSystemSites(availableSites || [])
    }
  }, [selectedSystem, allSystems, badge])

  const handleOnConnectBadgeToSystem = async () => {
    if (!selectedSystem || !badge.id) {
      setError(t('allBadges.errorSelectSystem'))
      return
    }
    try {
      const isApprovedByLocale =
        badge.approvalStatusByLocale[selectedSystemSiteData || 'en_GB'] === 'APPROVED'

      if (!isApprovedByLocale) {
        throw new Error(t('allBadges.badgeNotApproved'))
      }
      await connectBadgeToSystem({
        badgeId: badge.id,
        locale: selectedSystemSiteData,
        systemId: selectedSystem
      })
      await refetchBadges()
      resetConnectionForm()
    } catch (err) {
      setError(err.message)
    }
  }

  const handleOnDisconnectBadgeToSystem = async (systemId, locale) => {
    if (!systemId || !badge.id) {
      setError(t('allBadges.errorSomethingWentWrong'))
      return
    }
    await disconnectBadgeToSystem({
      badgeId: badge.id,
      systemId,
      locale
    })
    setError(null)
    await refetchBadges()
  }

  const resetConnectionForm = () => {
    setSelectedSystem(undefined)
    setSelectedSystemSiteData('')
    setError(null)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className={'flex justify-between items-center gap-3'}>
        <div className={'col-span-3'}>
          <Select
            value={selectedSystem}
            onChange={(value) => setSelectedSystem(value)}
            options={allSystemsOptions}
            renderListItem={renderListItem}
            freeInput={true}
            rawChangeEvent={false}
            isClearable={true}
            onInputChange={(input) => adminSearchDebounce(input)}
          />
        </div>
        <div className={'col-span-3'}>
          <Select
            value={selectedSystemSiteData}
            onChange={setSelectedSystemSiteData}
            options={availableSystemSites}
            isClearable={true}
            rawChangeEvent={false}
          />
        </div>
        <div className={'col-span-2 gap-3 flex '}>
          <div>
            <DocumentTextIcon
              onClick={() => setIsListOpen(true)}
              className={
                'cursor-pointer hover:bg-indigo-600 hover:text-white w-10 h-10 p-1 text-gray-500 rounded-md border'
              }
            />
          </div>
          <Button onClick={handleOnConnectBadgeToSystem} disabled={!selectedSystem}>
            {t('allBadges.button.add')}
          </Button>
        </div>
      </div>

      {error && <div className={'text-red-600 text-sm my-2'}>{error}</div>}
      <Dialog
        onClose={setIsListOpen}
        isOpen={isListOpen}
        title={t('allBadges.connectedSystemList')}
      >
        <div className={'grid grid-cols-1 gap-3'}>
          {badge.badgeToSystem?.map((bts, index) => (
            <div
              key={index}
              className={
                'bg-gray-100 flex justify-between items-center shadow-md p-2 rounded-md'
              }
            >
              <p className={'w-52'}>{getSystemName(bts.systemV2)}</p>
              <Flag locale={bts.locale} height={'35px'} />
              <TrashIcon
                onClick={() =>
                  handleOnDisconnectBadgeToSystem(bts.systemV2.id, bts.locale)
                }
                width={20}
                height={20}
                color={'red'}
                className="cursor-pointer"
              />
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  )
}

export default BadgeSystemConnection
