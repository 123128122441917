export const BADGE_TYPES = ['REVIEW']

export const DEFAULT_ATTRIBUTES = {
  headlineAttribute: {
    title: '',
    x: 220.0853729248047,
    y: 231.82927894592285,
    fontSize: 34
  },
  subHeadlineAttribute: {
    title: '',
    x: 222.69515991210938,
    y: 278.80491065979004,
    fontSize: 34
  },
  badgeTypeAttribute: {
    x: 6.3292694091796875,
    y: 19.329254150390625
  },
  yearAttribute: {
    x: 225.54876899719238,
    y: 393.82928371429443
  }
}

export const locales = ['en_GB', 'sv_SE', 'da_DK', 'nb_NO', 'fi_FI', 'de_DE']
