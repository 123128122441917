import Dialog from '../../../shared/dialog'
import BadgeTemplate from '../badge-generator/BadgeTemplate'
import React, { useContext, useEffect, useState } from 'react'
import BadgeContext from '../../context/BadgeContext'
import { useUpdateBadge } from '../../hooks/useUpdateBadge'
import Button from '../../../shared/button'
import Input from '../../../shared/input'
import Label from '../../../shared/label'
import { Select } from '../../../shared/select'
import { BADGE_TYPES } from '../../constants/badge-constants'
import { useTranslation } from 'react-i18next'

const EditBadgeDialog = ({ badge, onClose, isOpen, locale }) => {
  const { t } = useTranslation('adminPages')
  const {
    categoriesOptions,
    badges: { refetch }
  } = useContext(BadgeContext)

  const { updateBadge, loading, error } = useUpdateBadge()
  const [currentAttributesChange, setCurrentAttributesChange] = useState({
    ...badge.attributes[locale]
  })
  const [year, setYear] = useState(badge.year)
  const [expiration, setExpiration] = useState(badge.expiresAt)
  const [category, setCategory] = useState(badge.category)
  const [badgeType, setBadgeType] = useState(badge.type)

  const [rating, setRating] = useState(badge.rating)

  const handleBadgeAttributeUpdate = async () => {
    try {
      const updateData = {
        badgeId: badge.id,
        data: {
          attributes: {
            ...badge.attributes,
            [locale]: {
              ...badge.attributes[locale],
              ...currentAttributesChange
            }
          },
          category,
          year,
          rating,
          expiresAt: new Date(expiration),
          type: badgeType
        },
        shouldUpdateBucket: true
      }
      await updateBadge(updateData)
      await refetch()
    } catch (err) {
      console.error('Failed to update badge:', err)
    }
  }

  const handleBadgeAttributeChange = (key, updatedAttributes) => {
    setCurrentAttributesChange((prev) => ({
      ...prev,
      [key]: {
        ...currentAttributesChange[key],
        ...updatedAttributes[key]
      }
    }))
  }
  const handleClose = () => {
    onClose(false)
  }
  const handleChangeOfHeadlineFont = (e) => {
    handleBadgeAttributeChange('headlineAttribute', {
      headlineAttribute: {
        fontSize: parseInt(e.target.value)
      }
    })
  }

  const handleChangeOfCategoryFont = (e) => {
    handleBadgeAttributeChange('subHeadlineAttribute', {
      subHeadlineAttribute: {
        fontSize: parseInt(e.target.value)
      }
    })
  }
  useEffect(() => {
    setCurrentAttributesChange(badge.attributes[locale])
  }, [badge, locale])

  const customDialogFooter = () => {
    return (
      <div>
        <hr className="my-5" />
        {error && (
          <div className="text-red-500 text-sm">{t('allBadges.errorUpdatingBadge')}</div>
        )}
        <div className="flex justify-end gap-3">
          <Button
            loading={loading}
            disabled={loading}
            mode="primary"
            onClick={handleBadgeAttributeUpdate}
          >
            {t('allBadges.button.update')}
          </Button>
          <Button onClick={handleClose} mode="default">
            {t('allBadges.button.close')}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <Dialog
      renderFooter={customDialogFooter}
      className="!max-w-3xl"
      isOpen={isOpen}
      title={t('allBadges.editBadge')}
      onClose={handleClose}
    >
      <div className="grid-cols-2 grid gap-3">
        <div className="col-span-1">
          <div className="flex items-center gap-2 flex-wrap">
            <div className={'w-full flex gap-3 items-center'}>
              <Input
                className="w-full"
                value={currentAttributesChange.headlineAttribute.title}
                onChange={(value) =>
                  handleBadgeAttributeChange('headlineAttribute', {
                    headlineAttribute: { title: value }
                  })
                }
                helperText={t('allBadges.headline')}
              />
              <Input
                className="w-full"
                value={currentAttributesChange.subHeadlineAttribute.title}
                onChange={(value) =>
                  handleBadgeAttributeChange('subHeadlineAttribute', {
                    subHeadlineAttribute: { title: value }
                  })
                }
                helperText={t('allBadges.subHeadline')}
              />
            </div>
            <div className={'w-full flex gap-3 items-center'}>
              <div className="w-full">
                <label className="text-sm text-gray-500">{t('allBadges.expires')}</label>
                <input
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  type="date"
                  value={
                    expiration ? new Date(expiration).toISOString().split('T')[0] : ''
                  }
                  onChange={(e) => setExpiration(e.target.value)}
                  placeholder="e.g. Dec 2025"
                />
              </div>
              <Input
                className="w-full"
                value={year}
                onChange={setYear}
                helperText={t('allBadges.year')}
              />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-2">
            <div className="w-full">
              <Label
                labelClassname="!text-gray-500 font-normal"
                title={t('allBadges.selectCategory')}
                marginless
              >
                <Select
                  value={category}
                  onChange={setCategory}
                  rawChangeEvent={false}
                  options={categoriesOptions}
                />
              </Label>
            </div>
            <div className="w-full">
              <Label
                labelClassname="!text-gray-500 !font-normal"
                title={t('allBadges.badgeType')}
                marginless
              >
                <Select
                  value={badgeType}
                  onChange={setBadgeType}
                  rawChangeEvent={false}
                  options={BADGE_TYPES.map((type) => ({ value: type, label: type }))}
                />
              </Label>
            </div>
          </div>
          {badge.type === 'REVIEW' && (
            <div className={'my-4'}>
              <label htmlFor={'rating'} className={'text-xs font-medium'}>
                {t('badgeGenerator.selectedRating')} {rating}
              </label>
              <input
                type="range"
                id="rating"
                min="1"
                max="5"
                step="0.5"
                value={rating}
                onChange={(e) => setRating(Number(e.target.value))}
                className={'w-full'}
              />
            </div>
          )}
          <div className={'flex flex-col gap-3 items-center w-full my-4'}>
            <div className="flex flex-col items-center w-full">
              <label htmlFor="slider" className="mb-2 text-xs font-medium">
                {t('allBadges.headlineFont')}
              </label>
              <div className={'flex gap-3 items-center w-full'}>
                <input
                  id="slider"
                  type="range"
                  min="0"
                  max="100"
                  value={
                    currentAttributesChange?.headlineAttribute?.fontSize ||
                    badge.attributes[locale]?.headlineAttribute?.fontSize
                  }
                  onChange={handleChangeOfHeadlineFont}
                  style={{
                    background: `linear-gradient(to right, #4f46e5 ${
                      currentAttributesChange?.headlineAttribute?.fontSize ||
                      badge.attributes[locale]?.headlineAttribute?.fontSize
                    }%, #d3d3d3 ${
                      currentAttributesChange?.headlineAttribute?.fontSize ||
                      badge.attributes[locale]?.headlineAttribute?.fontSize
                    }%)`
                  }}
                  className="w-full h-2 rounded-lg appearance-none cursor-pointer focus:outline-none"
                />
                <p className={'text-xs text-end'}>
                  {currentAttributesChange?.headlineAttribute?.fontSize ||
                    badge.attributes[locale]?.headlineAttribute?.fontSize}{' '}
                  px
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center w-full">
              <label htmlFor="slider" className="mb-2 text-xs font-medium">
                {t('allBadges.categoryFont')}
              </label>
              <div className={'flex gap-3 items-center w-full'}>
                <input
                  id="slider"
                  type="range"
                  min="0"
                  max="100"
                  value={
                    currentAttributesChange?.subHeadlineAttribute?.fontSize ||
                    badge.attributes[locale]?.subHeadlineAttribute?.fontSize
                  }
                  onChange={handleChangeOfCategoryFont}
                  style={{
                    background: `linear-gradient(to right, #4f46e5 ${
                      currentAttributesChange?.subHeadlineAttribute?.fontSize ||
                      badge.attributes[locale]?.subHeadlineAttribute?.fontSize
                    }%, #d3d3d3 ${
                      currentAttributesChange?.subHeadlineAttribute?.fontSize ||
                      badge.attributes[locale]?.subHeadlineAttribute?.fontSize
                    }%)`
                  }}
                  className="w-full h-2 rounded-lg appearance-none cursor-pointer focus:outline-none"
                />
                <p className={'text-xs text-end'}>
                  {currentAttributesChange?.subHeadlineAttribute?.fontSize ||
                    badge.attributes[locale]?.subHeadlineAttribute?.fontSize}{' '}
                  px
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <BadgeTemplate
            onChange={handleBadgeAttributeChange}
            width={300}
            height={300}
            className="shadow-none !p-0"
            badgeType={badge.type}
            rating={rating}
            isDraggable={true}
            attributes={currentAttributesChange}
            category={badge.category}
            categoryLocale={locale}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default EditBadgeDialog
