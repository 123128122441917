import React from 'react'
import { PageHeader } from '../components/page-header'
import { useTranslation } from 'react-i18next'
import { PageBody } from '../components/page-body'
import useFetchGuideLeadInsights from '../components/leads-guide/hooks/useFetchGuideLeadInsights'
import Loader from '../components/loader'
import BarChartGraph from '../components/graphs/BarChartGraph'
import PieChartGraph from '../components/graphs/PieChartGraph'
import BarHorizontalChart from '../components/graphs/BarHorizontalChart'

const LeadsGuideInsightsPage = () => {
  const { t, i18n } = useTranslation(['leadsGuide', 'content'])
  const { getChartData, loading, error } = useFetchGuideLeadInsights({
    locale: i18n.language
  })

  if (loading) {
    return (
      <div className={'flex justify-center'}>
        <Loader />
      </div>
    )
  }

  const { role, companySize, buyersJourneyStage, country, industry } = getChartData()
  const formatBuyerStage = buyersJourneyStage.map((stage) => ({
    name: stage.label,
    count: stage.count
  }))
  const formatCountries = country?.map((country) => ({
    label: t(`shared:localeToCountry.${country.label}`),
    count: country.count
  }))

  return (
    <>
      <PageHeader pageName={t('leadsGuide:insights')} />
      <PageBody>
        <div className={'grid grid-cols-1 gap-4 md:gap-6 lg:grid-cols-2'}>
          <BarChartGraph
            data={companySize}
            barKey={'count'}
            dataKey={'label'}
            title={t('content:companySize')}
            showBarKeyXAxis
            rotateXAxisLabels
            showAllXAxisLabels
            barHeight={300}
            gapBottom={85}
          />
          <PieChartGraph
            data={formatBuyerStage}
            dataKey={'count'}
            nameKey={'name'}
            title={t('content:buyersJourneyStage')}
            height={400}
            mobileHeight={250}
          />
          {industry?.length <= 3 ? (
            <BarChartGraph
              data={industry}
              barKey={'count'}
              dataKey={'label'}
              title={t('content:industry')}
              showBarKeyXAxis
              showAllXAxisLabels
            />
          ) : (
            <BarHorizontalChart
              data={industry}
              barKey={'count'}
              dataKey={'label'}
              title={t('content:industry')}
            />
          )}
          <BarChartGraph
            data={formatCountries}
            barKey={'count'}
            dataKey={'label'}
            title={t('content:markets')}
            showBarKeyXAxis
            showAllXAxisLabels
          />
          <div className={'col-span-1 lg:col-span-2'}>
            <BarChartGraph
              data={role}
              barKey={'count'}
              dataKey={'label'}
              title={t('content:role')}
              showBarKeyXAxis
              showAllXAxisLabels
              rotateXAxisLabels
              barHeight={300}
              gapBottom={100}
            />
          </div>
        </div>
      </PageBody>
    </>
  )
}

export default LeadsGuideInsightsPage
