import React, { useContext, useState } from 'react'
import BadgeContext from '../../context/BadgeContext'
import Input from '../../../shared/input'
import { Select } from '../../../shared/select'
import Label from '../../../shared/label'
import { BADGE_TYPES, locales } from '../../constants/badge-constants'
import Button from '../../../shared/button'
import { useCreateBadge } from '../../hooks/useCreateBadge'
import cx from 'classnames'
import Flag from '../../../shared/flag'
import { useTranslation } from 'react-i18next'

function BadgeForm() {
  const { t } = useTranslation('adminPages')
  const { createBadge, loading, error: badgeCreateError } = useCreateBadge()
  const [error, setError] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})
  const {
    badges,
    badgeType,
    setBadgeType,
    expiration,
    setExpiration,
    categoriesOptions,
    selectedCategory,
    setSelectedCategory,
    attributes,
    updateAttributes,
    selectedCategoryLocale,
    setSelectedCategoryLocale,
    attributesByLocale,
    year,
    setYear,
    rating,
    setRating
  } = useContext(BadgeContext)

  const { headlineAttribute, subHeadlineAttribute } = attributes

  const validateForm = () => {
    const errors = {}

    if (!selectedCategory) {
      errors.category = t('badgeGenerator.validation.categoryRequired')
    }

    if (!headlineAttribute.title) {
      errors.headline = t('badgeGenerator.validation.headlineRequired')
    }

    if (!badgeType) {
      errors.badgeType = t('badgeGenerator.validation.badgeTypeRequired')
    }

    if (!year) {
      errors.year = t('badgeGenerator.validation.yearRequired')
    }

    if (!expiration) {
      errors.expiration = t('badgeGenerator.validation.expirationRequired')
    }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onCreateBadge = async () => {
    setError(null)

    if (!validateForm()) {
      return
    }

    try {
      const result = await createBadge({
        category: selectedCategory,
        type: badgeType,
        attributes: attributesByLocale,
        year,
        rating,
        expiresAt: expiration
      })
      if (result) {
        badges?.refetch()
      }
      setValidationErrors({})
      setError(null)
    } catch (err) {
      console.log(err)
      setError(t('badgeGenerator.failedToCreateBadge'))
    }
  }

  const handleChangeOfHeadlineFont = (e) => {
    updateAttributes('headlineAttribute', { fontSize: e.target.value })
  }

  const handleChangeOfCategoryFont = (e) => {
    updateAttributes('subHeadlineAttribute', { fontSize: e.target.value })
  }

  return (
    <div className="flex flex-col gap-5 max-md:flex-wrap">
      <div className="flex items-center gap-2 max-md:flex-wrap">
        <div className="w-full">
          <Input
            className="w-full"
            value={headlineAttribute.title}
            onChange={(value) => updateAttributes('headlineAttribute', { title: value })}
            helperText={t('badgeGenerator.headline')}
            error={validationErrors.headline}
            required
          />
        </div>
        <div className="w-full">
          <Input
            className="w-full"
            value={subHeadlineAttribute.title}
            onChange={(value) =>
              updateAttributes('subHeadlineAttribute', { title: value })
            }
            helperText={t('badgeGenerator.subHeadline')}
          />
        </div>
        <div className="w-full">
          <label className="text-sm text-gray-500">{t('badgeGenerator.expires')} *</label>
          <input
            className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                       block w-full sm:text-sm border-gray-300 rounded-md
                       ${validationErrors.expiration ? 'border-red-500' : ''}`}
            type="date"
            value={expiration}
            onChange={(e) => setExpiration(e.target.value)}
            placeholder="e.g. Dec 2025"
            required
          />
          {validationErrors.expiration && (
            <p className="text-red-500 text-xs mt-1">{validationErrors.expiration}</p>
          )}
        </div>
        <div className="w-full">
          <Input
            className="w-full"
            value={year}
            onChange={setYear}
            helperText={t('badgeGenerator.year')}
            error={validationErrors.year}
            required
          />
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div className="w-full">
          <Label
            labelClassname={`!text-gray-500 font-normal ${
              validationErrors.category ? '!text-red-500' : ''
            }`}
            title={`${t('badgeGenerator.selectCategory')} *`}
            marginless
          >
            <Select
              value={selectedCategory}
              onChange={setSelectedCategory}
              rawChangeEvent={false}
              options={categoriesOptions}
              className={validationErrors.category ? 'border-red-500' : ''}
            />
            {validationErrors.category && (
              <p className="text-red-500 text-xs mt-1">{validationErrors.category}</p>
            )}
          </Label>
        </div>
        <div className="w-full">
          <Label
            labelClassname={`!text-gray-500 !font-normal ${
              validationErrors.badgeType ? '!text-red-500' : ''
            }`}
            title={`${t('badgeGenerator.selectBadgeType')} *`}
            marginless
          >
            <Select
              value={badgeType}
              onChange={setBadgeType}
              rawChangeEvent={false}
              options={BADGE_TYPES.map((type) => ({ value: type, label: type }))}
              className={validationErrors.badgeType ? 'border-red-500' : ''}
            />
            {validationErrors.badgeType && (
              <p className="text-red-500 text-xs mt-1">{validationErrors.badgeType}</p>
            )}
          </Label>
        </div>
      </div>
      <hr />
      {badgeType === 'REVIEW' && (
        <div>
          <label htmlFor={'rating'} className={'text-xs font-medium'}>
            {t('badgeGenerator.selectedRating')} {rating}
          </label>
          <input
            type="range"
            id="rating"
            min="1"
            max="5"
            step="0.5"
            value={rating}
            onChange={(e) => setRating(Number(e.target.value))}
            className={'w-full'}
          />
        </div>
      )}
      <div className={'flex gap-3 items-center w-full max-md:flex-wrap'}>
        <div className="flex flex-col items-center w-full">
          <label htmlFor="sliderHeadline" className="mb-2 text-xs font-medium">
            {t('badgeGenerator.headlineFont')}
          </label>
          <div className={'flex gap-3 items-center w-full'}>
            <input
              id="sliderHeadline"
              type="range"
              min="0"
              max="100"
              value={headlineAttribute.fontSize}
              onChange={handleChangeOfHeadlineFont}
              style={{
                background: `linear-gradient(to right, #4f46e5 ${headlineAttribute.fontSize}%, #d3d3d3 ${headlineAttribute.fontSize}%)`
              }}
              className="w-full h-2 rounded-lg appearance-none cursor-pointer focus:outline-none"
            />
            <p className={'text-xs'}>{headlineAttribute.fontSize} px</p>
          </div>
        </div>
        <div className="flex flex-col items-center w-full">
          <label htmlFor="sliderCategory" className="mb-2 text-xs font-medium">
            {t('badgeGenerator.categoryFont')}
          </label>
          <div className={'flex gap-3 items-center w-full'}>
            <input
              id="sliderCategory"
              type="range"
              min="0"
              max="100"
              value={subHeadlineAttribute.fontSize}
              onChange={handleChangeOfCategoryFont}
              style={{
                background: `linear-gradient(to right, #4f46e5 ${subHeadlineAttribute.fontSize}%, #d3d3d3 ${subHeadlineAttribute.fontSize}%)`
              }}
              className="w-full h-2 rounded-lg appearance-none cursor-pointer focus:outline-none"
            />
            <p className={'text-xs'}>{subHeadlineAttribute.fontSize} px</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex justify-center gap-2 max-md:flex-wrap">
        {locales.map((locale) => {
          return (
            <div
              onClick={() => setSelectedCategoryLocale(locale)}
              key={locale}
              className={cx(
                'flex-shrink-0 flex justify-center items-center p-3 border-2 rounded-md cursor-pointer hover:border-indigo-600',
                {
                  'border-indigo-600': locale === selectedCategoryLocale
                }
              )}
            >
              <div className="flex  rounded-full bg-cover bg-center">
                <Flag height={'30px'} locale={locale} />
              </div>
            </div>
          )
        })}
      </div>
      <hr />
      {Object.keys(validationErrors).length > 0 && (
        <div className="text-red-600 mb-2 text-sm">
          {t('badgeGenerator.pleaseFixErrors')}
        </div>
      )}
      {error && <div className="text-red-600 mb-2 text-sm">{error}</div>}
      <div className="flex justify-end">
        <Button loading={loading} disabled={loading} onClick={onCreateBadge}>
          {t('badgeGenerator.button.createBadge')}
        </Button>
      </div>
    </div>
  )
}

export default BadgeForm
